@media (min-width: 769px) {
    .steps-section {
        text-align: center;
        margin: 48px 0;
    }

    .steps-header h2 {
        font-size: 38px;
        color: #002d6f;
    }

    .steps-header h3 {
        font-size: 1rem;
        font-weight: normal;
    }


    .steps-cards {
        margin-top: 20px;
    }

    .step-card {
        flex: 1;
        padding: 20px 0 0 0;
        background-color: #f5f5f5;
        margin: 0 10px;
        text-align: center;
        border-radius: 10px;

        h4 {
            font-size: 16px;
            font-weight: 700;
        }

        p {
            font-size: 14px;
        }
    }

    .step-circle {
        width: 40px;
        height: 40px;
        background-color: #00c292;
        border-radius: 50%;
        line-height: 40px;
        font-size: 18px;
        color: white;
        margin: -18px -18px 10px;
        position: absolute;
        float: left;
    }

    .step-card img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        border-radius: 5px;
    }
}

@media (max-width: 768px) {
    .steps-section {
        text-align: center;
        margin: 24px 0;
    }

    .steps-cards {
        display: grid;
    }
    .steps-header h2 {
        font-size: 18px;
        color: #002d6f;
    }

    .steps-header h3 {
        font-size: 12px;
        font-weight: normal;
    }

    .steps-header p {
        font-size: 12px;
    }

    .step-card {
        h4 {
            font-size: 10px;
            font-weight: 700;
        }

        p {
            font-size: 10px;
        }

        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 15px;
            border-radius: 5px;
        }
    }

    .step-circle {
        width: 24px;
        height: 24px;
        background-color: #00c292;
        border-radius: 50%;
        font-size: 14px;
        color: white;
        margin: 0 auto -10px;
        position: relative;
        text-align: center;
    }
}

.steps-section {
    .slick-slide {
        margin: 0 14px;
    }
}